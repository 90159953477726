import React, { useState, useContext, useEffect, useMemo } from 'react'
import styles from './Cards.module.scss'
import { Card, Modal, Hero, PriceCards, AddToCart, FixturesServices, CategoryInfo } from '../'
import Internet from '../../assets/svg/internet'
import Phone from '../../assets/svg/phone'
import classnames from 'classnames'
import { CartContext, getTotalCatPrice } from '../../contexts/Cart'
import { ProductContext } from '../../contexts/Products'
import { CategoriesContext } from '../../contexts/Categories'
import { DiscountsContext } from '../../contexts/Discounts'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown } from '@fortawesome/pro-regular-svg-icons'
import {
  getProduct,
  getProductBinding,
  getSelectedFixturesProducts,
  getSpecialProducts,
  getCategoryFixtures,
  getCategory,
  getSelectedDiscountKey,
  getDefaultFixtures
} from '../../calculations'

const DisplayHero = ({ modalData, type }) => <Hero
  type={type}
  title={modalData.title}
  subtitle={modalData.subtitle}
/>

const Cards = () => {
  const [modalKey, setModalKey] = useState('internet')
  const [showModal, setShowModal] = useState(false)
  const { state: dataStore, dispatch } = useContext(CartContext)
  const categories = useContext(CategoriesContext)
  const allProducts = useContext(ProductContext)
  const allDiscounts = useContext(DiscountsContext)
  const [priceCardSelected, setPriceCardSelected] = useState(null)
  const [fixturesSelected, setFixturesSelected] = useState(null)
  const [bindingSelected, setBindingSelected] = useState(0)

  const clickCard = (key) => {
    setModalKey(key)
    setShowModal(true)
  }

  const closeModal = () => {
    const product = getProduct(priceCardSelected)
    const binding = getProductBinding(product, bindingSelected)
    const discountKey = product && product.discountKey

    dispatch({
      type: 'addProduct',
      modalKey,
      priceCardSelected,
      fixturesSelected,
      binding,
      discountKey,
      allProducts,
      allDiscounts
    })
  
    setShowModal(false)
  }

  const totalPrice = useMemo(() => {
    const discountKey = dataStore.sjonvarp.discountKey
    return getTotalCatPrice(priceCardSelected, fixturesSelected, bindingSelected, discountKey, modalKey)
  }, [priceCardSelected, fixturesSelected, bindingSelected])

  const displayCards = () => {
    return categories.map(cat => {
      const { product: storeCard, fixtures: selectedFixtures, price } = dataStore[cat.type]
      const fixturesProducts = getSelectedFixturesProducts(selectedFixtures)
      const card = getProduct(storeCard.id)
      const isTv = cat.type === 'sjonvarp'

      const cardTitle = card
        ? card.name
        : cat.title

      const binding = isTv && storeCard.binding !== null
        ? bindingSelected
        : null

      const preHeading = []
      fixturesProducts.map(fix => {
        if (fix.preHeading) {
          preHeading.push(fix.preHeading)
        }
      })

      return (
        <div className='col-xs-12 col-md-4' key={cat.type}>
          <Card
            title={cardTitle}
            icon={<Internet />}
            description={cat.message}
            price={price.total}
            discount={price.discount}
            binding={binding}
            isTv={isTv}
            modalKey={modalKey}
            disabled={storeCard.id === null || !storeCard.id}
            preHeading={preHeading.length > 0 && preHeading.join(' ')}
            click={() => clickCard(cat.type)}
          />
        </div>
      )
    })
  }

  const onPriceCardSelect = (cardId) => {
    setPriceCardSelected(cardId && cardId !== priceCardSelected
      ? cardId
      : null
    )
  }

  const onBindingSelect = (months) => {
    setBindingSelected(months)
  }

  const onFixtureChange = (type, prodId = null, enabled = null, number = null) => {
    const newFixtureObj = {
      ...fixturesSelected,
      [type]: {
        ...fixturesSelected[type],
        ...(prodId && { id: prodId }),
        ...(enabled !== null && { enabled: enabled }),
        ...(number !== null && { number: number })
      }
    }
    setFixturesSelected(newFixtureObj)
  }

  useEffect(() => {
    const storeCard = dataStore[modalKey]

    if (storeCard && storeCard.product.id) {
      setPriceCardSelected(storeCard.product.id)
    } else {
      const defaultProduct = products.filter(item => item.isDefault === true)
      setPriceCardSelected(defaultProduct.length > 0 && defaultProduct[0].id)
    }

    if (storeCard && Object.entries(storeCard.fixtures).length > 0) {
      setFixturesSelected(storeCard.fixtures)
    } else {
      setFixturesSelected(
        getDefaultFixtures(modalKey)
      )
    }

  }, [modalKey])

  const storeCard = dataStore[modalKey]
  const products = getSpecialProducts(modalKey, storeCard.useSpecial)
  const catData = getCategory(modalKey)
  const fixtures = getCategoryFixtures(modalKey)
  const selectedDiscountKey = getSelectedDiscountKey(priceCardSelected)

  return (
    <>
      <div className={classnames('container', styles.cardsContainer)}>
        <div className='row'>
          {displayCards()}
        </div>
      </div>
      <Modal
        closeModal={closeModal}
        enabled={showModal}
      >
        {catData &&
          <DisplayHero
            modalData={catData.modal}
            type={modalKey}
          />
        }
        <div className={classnames('col-xs-12 col-md-10 col-md-offset-1', [styles.modalInnerContent])}>
          <PriceCards
            products={products}
            priceCardSelected={priceCardSelected}
            onPriceCardSelect={onPriceCardSelect}
            onBindingSelect={onBindingSelect}
            bindingSelected={bindingSelected}
            modalKey={modalKey}
            showModal={showModal}
          />
          <FixturesServices
            fixtures={fixtures}
            fixturesSelected={fixturesSelected}
            onChange={onFixtureChange}
            modalKey={modalKey}
            selectedDiscountKey={selectedDiscountKey}
            onAddToCart={closeModal}
            totalPrice={totalPrice}
          />
          <div className={styles.fixturesMore}>
            <FontAwesomeIcon icon={faArrowDown} />
            <p>{catData.seeMore}</p>
          </div>
        </div>
        <CategoryInfo
          title={catData.serviceInfoTitle}
          text={catData.serviceInfo}
        />
      </Modal>
    </>
  )
}

export default Cards
