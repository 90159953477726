import React, { useReducer, createContext } from 'react'
import defaultCart from '../data/cart.json'
import { ProductContext } from './Products'
import {
  getAllProducts,
  getAllDiscounts,
  getProduct,
  getProducts,
  getAllDidYouKnows,
  getSelectedFixtures
} from '../calculations'
export const CartContext = createContext()

const getSelectedFixturesTotalPrice = (selectedFixtures) => {
  const fixturesProducts = getSelectedFixtures(selectedFixtures)
  return fixturesProducts.reduce((acc, curr) => {
    return Object.entries(curr).length > 0
      ? curr.totalPrice + acc
      : acc
  }, 0)
}

const getActiveTypes = (cart) => {
  const cartArr = Object.entries(cart)
  return cartArr.reduce((acc, [type, c]) => {
    const product = c.product.id !== null
      ? getProduct(c.product.id)
      : null

    if (product !== null) {
      acc.push(product.type)
    }

    return acc
  }, [])  
}

export const getDisplayMessage = (cart) => {
  const typesActive = getActiveTypes(cart)
  const allDidYouKnows = getAllDidYouKnows()
  const key = typesActive.length > 0
    ? typesActive.join('')
    : 'none'

  return allDidYouKnows[key]
}

const getSelectedFixturesTotalDiscount = (selectedFixtures, realDiscountKey) => {
  const fixturesProducts = getSelectedFixtures(selectedFixtures)
  const allDiscounts = getAllDiscounts()
  return fixturesProducts.reduce((acc, curr) => {
    return Object.entries(curr).length > 0 && realDiscountKey && curr.discountKey
      ? allDiscounts[realDiscountKey][curr.discountKey] + acc
      : acc
  }, 0)
}

export const getTotalCatPrice = (priceCardSelected, fixturesSelected, bindingSelected, discountKey, modalKey) => {
  const tempDataStore = {
    [modalKey]: {
      product: {
        id: priceCardSelected,
        binding: bindingSelected
      },
      fixtures: { ...fixturesSelected }
    }
  }
  return getCatTotals(tempDataStore, modalKey, discountKey)
}

export const getTotalPrice = (cart) => {
  const cartArr = Object.entries(cart)
  const discountKey = cart.sjonvarp.discountKey

  return cartArr.reduce((totalPriceObj, [type, c]) => {
    const catTotals = getCatTotals(cart, type, discountKey)

    totalPriceObj.leapPrice += catTotals.leapPrice
    totalPriceObj.discount += catTotals.discount
    totalPriceObj.total += catTotals.total
    return totalPriceObj
  }, { leapPrice: 0, discount: 0, total: 0 })
}

export const getCatTotals = (state, modalKey, discountKey) => {
  const { product: storeCard, fixtures: selectedFixtures } = state[modalKey]
  const allProducts = getAllProducts()
  const allDiscounts = getAllDiscounts()
  const card = getProduct(storeCard.id)

  const selectedFixturesArr = Object.entries(selectedFixtures)
  const fixturesProducts = selectedFixturesArr.map(([fixType, fix]) => {
    const fixparentObj = fix.enabled
      ? allProducts.find(prod => prod.type === fixType)
      : null
    return fixparentObj
      ? fixparentObj.products.find(prod => {
        prod.totalPrice = Number.isInteger(fix.number)
          ? prod.price * fix.number
          : prod.price
        return (prod.id === fix.id)
      })
      : {}
  })

  const isTv = modalKey === 'sjonvarp'
  const realDiscountKey = isTv && card !== null
    ? card.discountKey
    : discountKey

  const fixturePrices = getSelectedFixturesTotalPrice(selectedFixtures)
  const fixtureDiscounts = getSelectedFixturesTotalDiscount(selectedFixtures, realDiscountKey)

  const binding = isTv && storeCard.binding !== null
    ? storeCard.binding
    : null

  let discount = realDiscountKey && card && allDiscounts[realDiscountKey][card.discountKey]
    ? allDiscounts[realDiscountKey][card.discountKey]
    : 0
  discount += fixtureDiscounts

  let cardPrice = card && binding !== null && card.binding !== null && card.binding[binding]
    ? card.binding[binding]
    : card
      ? card.price
      : 0
  cardPrice += fixturePrices

  return {
    leapPrice: cardPrice,
    discount: discount,
    total: cardPrice - discount
  }
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'addProduct': {
      const categoryUpdate = {
        [action.modalKey]: {
          ...state[action.modalKey],
          ...{
            "product": {
              ...state[action.modalKey].product,
              ...{ "id": action.priceCardSelected },
              ...{ "binding": action.binding }
            },
            ...{ "fixtures": action.fixturesSelected },
            ...{ "discountKey": action.discountKey }
          }
        }
      }

      const tempState = { ...state, ...categoryUpdate }
      const { allProducts, allDiscounts, modalKey } = action
      const useSpecial = tempState.sjonvarp
        && tempState.internet
        && tempState.sjonvarp.product.id
        && tempState.internet.discountKey === 'internetOtakmarkad'
        ? true
        : false

      tempState.farsimi.useSpecial = useSpecial
      const currentFarsimi = allProducts.find(item => (item.id === tempState.farsimi.product.id))

      if (currentFarsimi && currentFarsimi.isSpecialOffer !== useSpecial) {
        tempState.farsimi.product.id = null
      }

      Object.entries(tempState).forEach(([ key, value ]) => {
        if (key !== 'price') {
          const discountKey = tempState.sjonvarp.discountKey
          if (typeof tempState[key] !== 'undefined') {
            tempState[key].price = getCatTotals(tempState, key, discountKey)
          }
        }
      })

      return tempState
    }

    default: {
      console.log('DataContext missing type', action.type)
      return state
    }
  }
}

export const CartProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, defaultCart)
  const value = { state, dispatch }
  return <CartContext.Provider value={value}>{children}</CartContext.Provider>
}