import allProducts from '../data/products'
import allCategories from '../data/categories'
import allDiscounts from '../data/discounts'
import allDidYouKnows from '../data/didyouknow'

export const getSelectedDiscountKey = (id) => {
  const card = getProduct(id)
  return card && card.type === 'sjonvarp'
    ? card.discountKey
    : null
}

export const getAllProducts = () => allProducts
export const getAllDiscounts = () => allDiscounts
export const getAllDidYouKnows = () => allDidYouKnows

export const getCategory = (type) => {
  return allCategories.find(obj => obj.type === type)
}

export const getProduct = (id) => {
  return id
    ? allProducts.find(prod => prod.id === id)
    : null
}

export const getProducts = (type) => {
  return allProducts.filter(item => item.type === type && !item.isFixture && !item.isSpecialOffer)
}

export const getSpecialProducts = (type, useSpecial) => {
  return useSpecial
    ? allProducts.filter(item => item.type === type && !item.isFixture && item.isSpecialOffer)
    : getProducts(type)
}

export const getProductBinding = (product, bindingSelected) => {
  return product && product.binding !== null
    ? bindingSelected
    : null
}

export const getDefaultFixtures = (type) => {
  const fixtures = getCategoryFixtures(type)

  const fixObj = {}
  fixtures && fixtures.length > 0 && fixtures.map(({ type, products, enabled }) => {
    const defaultProd = products.find(item => item.isSelected === true)
    fixObj[type] = {
      id: defaultProd.id,
      enabled: enabled,
      ...(defaultProd.canHaveMultiple === true && { number: 0 })
    }
  })
  return fixObj
}

export const getAllFixtures = () => {
  return allProducts.filter(item => item.isFixture)
}

export const getCategoryFixtures = (type) => {
  const catData = getCategory(type)
  const allFixtures = allProducts.filter(item => item.isFixture)
  return catData && catData.fixtures.length > 0 &&
    catData.fixtures.map(item =>
      allFixtures.find(fix => fix.type === item)
    )
}

const getFixtureParentObj = (fix, fixType) => {
  return fix.enabled
    ? allProducts.find(prod => prod.type === fixType)
    : null
}

export const getSelectedFixturesProducts = (selectedFixtures) => {
  const selectedFixturesArr = Object.entries(selectedFixtures)
  return selectedFixturesArr.map(([fixType, fix]) => {

    const fixparentObj = getFixtureParentObj(fix, fixType)
    return fixparentObj
      ? fixparentObj.products.find(prod => (prod.id === fix.id))
      : {}
  })
}

export const getSelectedFixtures = (selectedFixtures) => {
  const selectedFixturesArr = Object.entries(selectedFixtures)
  return selectedFixturesArr.map(([fixType, fix]) => {
    const fixparentObj = getFixtureParentObj(fix, fixType)
    return fixparentObj
      ? fixparentObj.products.find(prod => {
        prod.totalPrice = Number.isInteger(fix.number)
          ? prod.price * fix.number
          : prod.price
        return (prod.id === fix.id)
      })
      : {}
  })
}
