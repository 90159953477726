import React, { useContext } from 'react'
import styles from './Hero.module.scss'
import classnames from 'classnames'

const Hero = ({ title, subtitle, image = '', type = '' }) => {
  const heroImage = { backgroundImage: `url(${image})` }
  return (
    <div
      className={classnames(styles.heroContainer, {
        [styles.isInModal] : type,
        [styles.heroContainerNoImage] : image === ''
      })}
      style={heroImage}
    >
      <div className='container'>
        <div className='row'>
          <div className={classnames({
            ['col-xs-12']: !type,
            ['col-xs-10 col-md-7 col-xs-offset-1'] : type
            })}>
            <h1>{title}</h1>
            {subtitle.length > 0 &&
              <h2>{subtitle}</h2>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default Hero
