import React, { useState } from 'react'
import Swiper from 'react-id-swiper'
import 'swiper/swiper.scss'
import styles from './Slider.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-light-svg-icons'
import classNames from 'classnames'

const Slider = ({slidesPerView, spaceBetween, children, modalKey}) => {
  const [swiper, setSwiper] = useState(null)
  const [activeArrow, setActiveArrow] = useState(false)

  const goNext = () => {
    if(activeArrow === false) {
      setActiveArrow(true)
      return (
        swiper !== null ? swiper.slideNext() : null
      )
    }
  }

  const goPrev = () => {
    if(activeArrow) {
      setActiveArrow(false)     
      return (
        swiper !== null ? swiper.slidePrev() : null
      )
    }
  }

  const params = {
    slidesPerView: 1.2,
    centeredSlides: false,
    spaceBetween: spaceBetween,
    grabCursor: true,
    containerClass: styles.vodaSwiperContainer,
    wrapperClass: styles.vodaSwiperWrapper,
    slideClass: styles.vodaSwiperSlide,
    shouldSwiperUpdate: true,
    observer: true,
    breakpoints: {
      1024: {
        slidesPerView: slidesPerView,
        spaceBetween: spaceBetween
      },
      768: {
        slidesPerView: 2.2,
        spaceBetween: spaceBetween
      },
      640: {
        slidesPerView: 2.2,
        spaceBetween: spaceBetween
      }
    }
  }

  return (
    <>
      {modalKey === 'sjonvarp' &&
      <>
        <span
          className={
            classNames(
              styles.arrow,
              {[styles.arrowActive]: activeArrow}
            )
          }
          onClick={() => goPrev()}
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </span>
        <span
          className={
            classNames(
              styles.arrow,
              {[styles.arrowActive]: !activeArrow}
            )
          }
          onClick={() => goNext()}
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </span>
      </>
    }
      <Swiper
        getSwiper={setSwiper}
        {...params}
      >
        {children}
      </Swiper>
    </>
  )
}

export default Slider