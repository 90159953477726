import React from 'react'
import styles from './Card.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faPencil } from '@fortawesome/pro-light-svg-icons'
import classnames from 'classnames'
import Tooltip from '../Tooltip'
import { formatPrice } from '../../helpers'

const Card = ({
  title,
  icon,
  description,
  disabled,
  binding,
  isTv,
  discount = 0,
  price,
  click,
  preHeading = ''
}) => {
  const showBinding = binding !== null
  const showTvHeading = isTv && !disabled && preHeading

  return (
    <div className={classnames(styles.cardItem, {
      [styles.cardItemActive]: !disabled
    })} onClick={click}>
      <div className={styles.cardHeading}>
        <div className={styles.cardHeadingInner}>
          <FontAwesomeIcon
            icon={faCheck}
            className={classnames([styles.cardTick], {
              [styles.cardDisabled]: disabled
            })}
          />
          {showTvHeading &&
            <div className={styles.cardTvHeading}>
              <p>
                <span className={styles.cardIcon}>{icon}</span>
                {preHeading}
              </p>
              <h2>
                {title}
                {showBinding &&
                  <div className={styles.cardBinding}>
                    {binding}
                  </div>
                }
              </h2>
            </div>
          }
          {!showTvHeading &&
            <h2>
              <span className={styles.cardIcon}>{icon}</span>
              {title}
              {showBinding &&
                <div className={styles.cardBinding}>
                  {binding}
                </div>
              }
            </h2>
          }
        </div>
        {!disabled &&
          <div className={styles.cardChange}>
            <FontAwesomeIcon
              icon={faPencil}
            />
          </div>
        }
      </div>
      <div className={classnames([styles.cardBody], {
        [styles.cardBodyDisplay]: !disabled
      })}>
        {disabled &&
          <p>{description}</p>
        }
        {!disabled &&
          <>
            <div>
              <p>Þú sparar:</p>
              <p className={styles.dFlex}>
                <span className={styles.cardPrice}>{formatPrice(discount)}</span>
                <Tooltip
                  tip='þetta er texti'
                />
              </p>
            </div>
            <div className={styles.alignRight}>
              <p>Verð:</p>
              <p><span className={styles.cardPrice}>{formatPrice(price)}</span></p>
            </div>
          </>
        }
      </div>
    </div>
  )
}

export default Card
