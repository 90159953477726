import React, { useState, useContext, useEffect } from 'react'
import styles from './PriceCards.module.scss'
import { PriceCard, Slider } from '../'
import Internet from '../../assets/svg/internet'
import Phone from '../../assets/svg/phone'
import classnames from 'classnames'

const isMobile = window.innerHeight < 769 ? true : false

const PriceCardWrapper = ({
  type,
  name,
  id,
  isDefault,
  includes,
  price,
  discountKey,
  binding,
  i,
  productLength,
  priceCardSelected,
  onClickCard,
  onBindingSelect,
  bindingSelected,
  modalKey,
  content
}) => {
  const priceCardClasses = classnames({
    [styles.ml0]: i === 0,
    [styles.mr0]: i === productLength - 1,
  })
  const selected = id === priceCardSelected
  const setSelectedBinding = (binding) => {
    onBindingSelect(binding)
  }

  return (
    <div className={classnames(styles.priceCardContainer, {
      [styles.priceCardContainerSelected]: selected
    })}>
      <PriceCard
        className={priceCardClasses}
        title={name}
        icon={<Internet />}
        description={name}
        selected={selected}
        includes={includes}
        price={price}
        discountKey={discountKey}
        type={type}
        modalKey={modalKey}
        binding={binding}
        selectedBinding={bindingSelected}
        onSelectBinding={setSelectedBinding}
        content={content}
        click={() => onClickCard(id)}
      />
    </div>
  )
}

const PriceCards = ({
  products,
  priceCardSelected = null,
  onPriceCardSelect,
  modalKey,
  onBindingSelect,
  bindingSelected,
  showModal
}) => {
  const [ hasChanged, setHasChanged ] = useState(false)
  const [ currentCardId, setCurrentCardId ] = useState(null)

  const updateCardId = (id) => {
    if (id) {
      onPriceCardSelect(id)
    }
  }

  useEffect((currentCardId) => {
    updateCardId(currentCardId)
  }, [currentCardId])

  const onClickCard = (id) => {
    setHasChanged(true)
    updateCardId(id)
    setCurrentCardId(id)
  }

  const displayCards = () => (
    products.map(({...props}, i) => {
      return (
        <div key={i}>
          <PriceCardWrapper
            i={i}
            productLength={products.length}
            priceCardSelected={priceCardSelected}
            onClickCard={onClickCard}
            onBindingSelect={onBindingSelect}
            bindingSelected={bindingSelected}
            modalKey={modalKey}
            {...props}
          />
        </div>
      )
    })
  )
  const slidesPerView = modalKey === 'sjonvarp'
    ? 'auto'
    : modalKey === 'farsimi'
      ? 5
      : 3
  const spaceBetween = modalKey === 'internet' & !isMobile
    ? 65
    : 10
  return (
    <div className='container'>
      <div className={styles.priceCardsContainer}>
        {showModal &&
          <Slider
            slidesPerView={slidesPerView}
            spaceBetween={spaceBetween}
            modalKey={modalKey}
          >
            {displayCards()}
          </Slider>
        }
      </div>
    </div>
  )
}

export default PriceCards
