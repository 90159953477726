import React, { useState, Fragment, useContext } from 'react'
import styles from './FixturesServices.module.scss'
import classnames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTag } from '@fortawesome/pro-light-svg-icons'
import { faChevronDown, faChevronUp, faArrowDown } from '@fortawesome/pro-regular-svg-icons'
import { DataCards, AddToCart } from '../'
import { CartContext } from '../../contexts/Cart'
import { DiscountsContext } from '../../contexts/Discounts'
import { formatPrice } from '../../helpers'

const FixtureService = ({
  title,
  type,
  select,
  canChange,
  price,
  fixtureDiscountKey,
  hasDropDown = false,
  products,
  enabled,
  click,
  selectedDiscountKey
}) => {
  const { state: dataStore } = useContext(CartContext)
  const allDiscounts = useContext(DiscountsContext)
  const discountKey = selectedDiscountKey !== null
    ? selectedDiscountKey
    : dataStore && dataStore.sjonvarp.discountKey
  const discounts = discountKey && allDiscounts[discountKey]
  const [productOpen, setProductOpen] = useState(false)
  const discount = fixtureDiscountKey && discounts
    ? discounts[fixtureDiscountKey]
    : 0
  const totalPrice = price - discount

  const onSelect = (type, prodId = null, change = null) => {
    if (canChange) {
      setProductOpen(false);
      select(type, prodId, change)
    }
  }
  const clickArrow = () => {
    setProductOpen(!productOpen)
  }

  const arrowIcon = productOpen
    ? faChevronUp
    : faChevronDown

  return (
    <div className={styles.fixtureOuter}>
      <div className={styles.fixtureService}>
        <div
          className={classnames(styles.fixtureTickBox, {
            [styles.fixtureDisabled]: !canChange,
            [styles.fixtureEnabled]: enabled
          })}
          onClick={() => onSelect(type, null, !enabled)}
        >
          <FontAwesomeIcon
            icon={faCheck}
            className={classnames([styles.fixtureTick])}
          />
        </div>
        <div className={styles.fixtureContent}>
          <div className={styles.fixtureHeading}>
            {title}
          </div>
          <div className={styles.fixturePrice}>
            {discount > 0 &&
              <span className={styles.fixtureDiscount}>{formatPrice(price)}</span>
            }
            {formatPrice(totalPrice)}
          </div>
        </div>
        {hasDropDown &&
          <div className={styles.fixtureArrow} onClick={clickArrow}>
            <FontAwesomeIcon
              icon={arrowIcon}
              className={classnames([styles.fixtureTick])}
            />
          </div>
        }
        {productOpen &&
          <div className={styles.fixturePopup}>
            {products.map(({ price, title, id, discountKey, ...props }, i) => {
              const discount = discounts && discountKey
                ? discounts[discountKey]
                : 0
              const totalPrice = price - discount

              return (
                <div
                  key={i}
                  onClick={() => onSelect(type, id)}
                  className={styles.fixturePopupItem}
                >
                  <div className={styles.fixtureHeading}>
                    {title}
                    {i === 0 &&
                      <FontAwesomeIcon
                        icon={arrowIcon}
                        className={classnames([styles.fixtureArrow])}
                      />
                    }
                  </div>
                  <div className={styles.fixturePrice}>
                    {discount > 0 &&
                      <span className={styles.fixtureDiscount}>{formatPrice(formatPrice)}</span>
                    }
                    {formatPrice(totalPrice)}
                  </div>
                </div>
              )
            })}
          </div>
        }
      </div>
    </div>
  )
}
const FixturesServices = ({ fixtures, fixturesSelected, onChange, modalKey, onAddToCart, totalPrice, selectedDiscountKey }) => (
  <div className={styles.fixturesContainer}>
    {fixtures && fixtures.length > 0 &&
      <>
        <h2>Búnaður og þjónusta</h2>
        <div className={styles.fixturesLine} />
      </>
    }
    <div className={styles.fixturesWrapper}>
      {fixtures.length > 0 && fixtures.map((fixture, i) => {
        const { products, canChange, type } = fixture
        const [selectedProduct] = products.filter(prod => {
          if (fixturesSelected && fixturesSelected[type]) {
            return fixturesSelected[type].id
              ? prod.id === fixturesSelected[type].id
              : false
          } else {
            return prod.isSelected
          }
        })

        const counter = fixturesSelected && fixturesSelected[type]
          ? fixturesSelected[type].number
          : 0
        const enabled = fixturesSelected && fixturesSelected[type] && fixturesSelected[type].enabled
        return (
          <Fragment key={i}>
            {modalKey !== 'farsimi' &&
              <FixtureService
                title={selectedProduct.title}
                type={fixture.type}
                price={selectedProduct.price}
                fixtureDiscountKey={selectedProduct.discountKey}
                hasDropDown={products.length > 1}
                products={products}
                canChange={canChange}
                select={onChange}
                enabled={enabled}
                selectedDiscountKey={selectedDiscountKey}
              />
            }
            {modalKey === 'farsimi' &&
              <DataCards
                title={selectedProduct.title}
                price={selectedProduct.price}
                id={selectedProduct.id}
                canChange={canChange}
                counter={counter}
                select={onChange}
                type={fixture.type}
              />
            }
          </Fragment>
        )
      })}
      <div className={styles.fixturesCartWrapper}>
        <div className={styles.totalsIcon}>
          <FontAwesomeIcon icon={faTag} />
        </div>
        <div className={styles.chartTotalText}>
          <p>
            Með kaupum á Skemmtipakkanum og Ótakmörkuðu interneti sparar þú 5.000 kr.
          </p>
          <p>
            Þitt verð: <strong>{totalPrice && formatPrice(totalPrice.total, true)}</strong> kr./mán.
          </p>
        </div>
        <AddToCart click={onAddToCart} />
      </div>
    </div>
  </div>
)

export default FixturesServices
