import React, { useState, useEffect } from 'react'
import styles from './DataCards.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus, faPlus } from '@fortawesome/pro-solid-svg-icons'
import classnames from 'classnames'
import IconKortKrakka from '../../assets/svg/IconKortGagna.js'
import { formatPrice } from '../../helpers'

const MAX = 10
const MIN = 0

const DataCards = ({ title, price, change, type, select, id, counter = 0 }) => {
  const [totalPrice, setTotalPrice] = useState(price)

  useEffect(() => {
    setTotalPrice(counter > 0
      ? counter * price
      : price
    )
  }, [counter])

  const onChange = (count) => {
    if (count >= MIN && count <= MAX) {
      select(type, id, change, count)
    }
  }
  return (
    <div className={styles.dataCard}>
      <IconKortKrakka />
      <div className={styles.dataCardPrice} >{formatPrice(totalPrice)}/mán.</div>
      <div className={styles.dataCardProductTitle}>{title}</div>
      <div className={styles.dataCardCounter}>
        <div className={classnames(styles.dataCardCounterIcon,
          { [styles.dataCardGreen]: counter > MIN },
          { [styles.dataCardGrey]: counter <= MIN }
        )}>
          <FontAwesomeIcon icon={faMinus} onClick={() => onChange(counter - 1)}/>
        </div>
        <p>{counter}</p>
          <div className={classnames(styles.dataCardCounterIcon,
            { [styles.dataCardGreen]: counter < MAX },
            { [styles.dataCardGrey]: counter >= MAX }
          )}>
            <FontAwesomeIcon icon={faPlus} onClick={() => onChange(counter + 1)} />
          </div>
        </div>
      </div>
      )
    }
    
    export default DataCards
