import React, { useEffect } from 'react'
import styles from './Modal.module.scss'
import classnames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-light-svg-icons'

const Modal = ({ children, closeModal, enabled }) => {
  const onInnerClick = (e) => {
    e.stopPropagation()
  }

  const onOuterClick = (e) => {
    closeModal()
  }

  useEffect(() => {
    const body = document.querySelector('body')
    if (enabled) {
      body.classList.add('modal-open')
    } else {
      body.classList.remove('modal-open')
    }

  }, [enabled])

  return (
    <div className={classnames(styles.modalOuter, {
      [styles.modalOuterEnabled] : enabled
    })}>
      <div
        className={styles.modalWrapper}
        onClick={onOuterClick}
      >
        <div className='container'>
          <div
            className={classnames(styles.modalInner, {
              [styles.modalInnerEnabled] : enabled
            })}
            onClick={onInnerClick}
          >
            {children}
            <div
              className={styles.modalClose}
              onClick={closeModal}
            >
              <FontAwesomeIcon
                icon={faTimes}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Modal
