import React, { useContext, useState, useEffect, useMemo, Fragment } from 'react'
import styles from './Totals.module.scss'
import classnames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTag } from '@fortawesome/pro-light-svg-icons'
import { DidyouknowContext } from '../../contexts/Didyouknow'
import { CartContext, getTotalPrice, getDisplayMessage } from '../../contexts/Cart'
import { formatPrice } from '../../helpers'

const Totals = () => {
  const [ message, setMessage ] = useState([])
  const [ messageEnabled, setMessageEnabled ] = useState(false)
  const didyouknow = useContext(DidyouknowContext)
  const { state: cart } = useContext(CartContext)

  const totalPrice = useMemo(() => {
    return getTotalPrice(cart)
  }, [cart])

  const displayMessage = getDisplayMessage(cart)

  const removeFirst = () => {
    if (message.length > 1) {
      message.shift()
      setMessage(message)
    }
  }

  useEffect(() => {
    setMessage([...message, displayMessage])
  }, [displayMessage])

  return (
    <Fragment>
      {/* desktop */}
      <div className={classnames('container', [styles.totalsContainer])}>
        <div className={classnames('row', [styles.totalsRow])}>
          <div className={classnames('col-xs-12 col-md-5', [styles.totalsFlex])}>
            <div className={styles.totalsIcon}>
              <FontAwesomeIcon icon={faTag} />
            </div>
            <div className={styles.messageOuterContainer}>
              <p className={styles.totalLargeText}>Vissir þú að</p>
              <div className={styles.messageContainer}>
                {message.map((m, i) => {
                  const removeMessage = message.length > 1 && i === 0
                  return (
                    <p key={i} className={classnames(styles.messageText, {
                      [styles.messageTextGoOut]: removeMessage,
                      [styles.messageTextGoIn]: !removeMessage
                    })}
                    onAnimationEnd={removeFirst}>{m}</p>
                  )
                })}
              </div>
            </div>
          </div>
          <div className={classnames('col-xs-12 col-md-5', [styles.totalsFlex], [styles.totalsFlexRight])}>
            <p className={styles.totalLargeText}>Þú sparar
            <span className={styles.totalPriceText}>{formatPrice(totalPrice.discount, true)}</span>
              <span className={styles.totalSmallText}>kr./mán.</span>
            </p>
            <div className={styles.totalSpacer}/>
            <p className={styles.totalLargeText}>Þitt verð
            <span className={styles.totalPriceText}>{formatPrice(totalPrice.total, true)}</span>
              <span className={styles.totalSmallText}>kr./mán.</span>
            </p>
          </div>
          <div className='col-xs-12 col-md-2'>
            <button className={classnames('btn btn-vodafone', [styles.totalButton])}>Setja í körfu</button>
          </div>
        </div>
      </div>
      {/* Mobile */}
      <div className={classnames('container', [styles.totalsMobileContainer])}>
        <div className={classnames('row', [styles.totalsRow])}>
          <div className={styles.totalsIcon}>
            <FontAwesomeIcon icon={faTag} />
          </div>
          <div className={classnames('col-xs-4', [styles.totalsFlex])}>
            <div>
              <p className={styles.totalLargeText}>Vissir þú að</p>
              <p className={classnames(
                styles.messageText,
                {[styles.messageTextEnabled]: messageEnabled}
              )}>
                {message}
              </p>
            </div>
          </div>
          <div className={classnames(
            'col-xs-8',
            [styles.totalsFlex],
            [styles.totalsFlexCol]
            )}>
            <div className={classnames(styles.totalSavedCol)}>
              <div>
                <p>Þú sparar</p>
                <p className={styles.totalPriceText}>
                  {formatPrice(totalPrice.discount)}
                </p>
                <p className={styles.totalSmallText}>á mánuði</p>
              </div>
              <div className={styles.totalSpacer} />
              <div>
                <p>Þitt verð</p>
                <p className={styles.totalPriceText}>{formatPrice(totalPrice.total)}</p>
                <p className={styles.totalSmallText}>á mánuði *</p>
              </div>
            </div>
            <button className={classnames('btn btn-vodafone', [styles.totalButton])}>Setja í körfu</button>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default Totals
