import React, { useContext, useState } from 'react'
import styles from './PriceCard.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTag, faChevronDown, faChevronUp } from '@fortawesome/pro-light-svg-icons'
import classnames from 'classnames'
import { DiscountsContext } from '../../contexts/Discounts'
import { CartContext } from '../../contexts/Cart'
import Tooltip from '../Tooltip'
import { formatPrice } from '../../helpers'

const PriceCardFeature = ({
    smallText = '',
    bigText = '',
    bigTextEnding = '',
    displayPrice = false,
    price = 0,
    discount = 0,
    binding = null,
    isTv = false,
    selectedBinding = 0,
  }) => {
  const hasSelectedBinding = binding && binding.hasOwnProperty(selectedBinding)
  const smallTextDisplay = displayPrice
    ? binding
      ? selectedBinding === 0 && hasSelectedBinding
        ? 'Mánaðarverð án bindingar'
        : `Mánaðarverð með ${selectedBinding} mán. bindingu`
      : 'Mánaðarverð'
    : smallText

  const bigTextDisplay = displayPrice
    ? binding && hasSelectedBinding
      ? formatPrice(binding[selectedBinding])
      : formatPrice(price - discount, true)
    : bigText

  const bigTextEndingDisplay = displayPrice
    ? 'kr.'
    : bigTextEnding

  const hasBinding = binding && Object.entries(binding).length > 0
  return (
    <div className={classnames(styles.priceCardFeature, {
      [styles.priceCardFeatureLeftAlign]: isTv
    })}>
      {!isTv &&
        <FontAwesomeIcon
          icon={faTag}
        />
      }
      <p>
        {smallTextDisplay}
        {displayPrice && discount > 0 &&
          <span className={styles.priceCardStrike}>{formatPrice(price)}</span>
        }
      </p>
      {bigTextDisplay !== '' &&
        <h3>
          {bigTextDisplay}
          {bigTextEndingDisplay !== '' &&
            <span>{bigTextEndingDisplay}</span>
          }
        </h3>
      }
    </div>
  )
}

const PriceCardBinding = ({ binding, displayBinding, selectedBinding = 0, onSelectBinding }) => (
  <div className={classnames(styles.priceCardBindings, {
    [styles.priceCardBindingsShow]: displayBinding
  })}>
    {Object.entries(binding).map(([monthsStr, price]) => {
      const months = parseInt(monthsStr, 10)
      const monthText = months === 0
      ? 'án.'
      : `${months} mán.`
      const displayPrice = formatPrice(price)
      const isSelected = months === selectedBinding

      return (
        <div
          key={months}
          className={classnames(styles.priceCardBinding, {
            [styles.priceCardBindingSelected]: isSelected
          })}
          onClick={() => onSelectBinding(months)}
        >
          <div>
            <div className={styles.priceCardBindingCheckbox}/>
            <div className={styles.priceCardBindingMonths}>
              {monthText}
            </div>
          </div>
          <div className={styles.priceCardBindingPrice}>
            {displayPrice}
          </div>
        </div>
      )
    })}
  </div>
)

const PriceCardFeatureBinding = ({ click, isOpen, children }) => {
  const icon = isOpen
    ? faChevronUp
    : faChevronDown

  return (
    <div
      className={styles.PriceCardPriceBindingWrapper}
      onClick={click}
    >
      <div className={styles.PriceCardFeatureBindingChevron}>
        <FontAwesomeIcon
          icon={icon}
        />
      </div>
      {children}
    </div>
  )
}

const PriceCard = ({
    title,
    icon,
    selected,
    click,
    includes,
    price,
    className,
    discountKey,
    type,
    binding,
    selectedBinding,
    onSelectBinding,
    modalKey,
    content
  }) => {
  const priceCardClasses = classnames(styles.priceCardItem, className, {
    [styles.priceCardSelected]: selected,
    [styles.priceCardWithMargin]: modalKey === 'farsimi'
    })

  const titleEnding = title.slice(-2).toLowerCase()
  const displaySmallEnding = titleEnding === 'gb'|| titleEnding === 'mb'
  const titleText = displaySmallEnding
    ? title.slice(0, title.length - 2)
    : title

  const allDiscounts = useContext(DiscountsContext)
  const { state: dataStore } = useContext(CartContext)
  const [ displayBinding, setDisplayBinding ] = useState(false)

  const bindingBodyClick = (e) => {
    e.stopPropagation()
    setDisplayBinding(!displayBinding)
  }

  const onBindingSelect = (months) => {
    setDisplayBinding(!displayBinding)
    onSelectBinding(months)
  }

  const discountTvKey = dataStore && dataStore.sjonvarp.discountKey
  const discount = discountKey && discountTvKey
    ? allDiscounts[discountTvKey][discountKey]
    : 0

  const isTv = type === 'sjonvarp'

  return (
    <>
      <div className={priceCardClasses} onClick={click}>
        <div className={styles.priceCardHeading}>
          <div className={styles.priceCardIcon}>{icon}</div>
          <h2>
            {titleText}
            {displaySmallEnding &&
              <span>{titleEnding}</span>
            }
          </h2>
          <div className={styles.priceCardOption} />
        </div>
        <div className={styles.priceCardBody}>
          {content &&
            <div className={styles.priceCardHtml} dangerouslySetInnerHTML={{ __html: content }} />
          }
          {includes.map(({ smallText, bigText, bigTextEnding }, i) => (
            <PriceCardFeature
              key={i}
              smallText={smallText}
              bigText={bigText}
              bigTextEnding={bigTextEnding}
            />
          ))}
          {!binding &&
            <PriceCardFeature
              displayPrice
              price={price}
              discount={discount}
              isTv={isTv}
            />
          }
          {binding &&
            <PriceCardFeatureBinding
              isOpen={displayBinding}
              click={(e) => bindingBodyClick(e)}
            >
              <PriceCardFeature
                displayPrice
                price={price}
                discount={discount}
                isTv={isTv}
                selectedBinding={selectedBinding}
                binding={binding}
              />
            </PriceCardFeatureBinding>
          }
        </div>
      </div>
      {binding &&
        <PriceCardBinding
          displayBinding={displayBinding}
          binding={binding}
          selectedBinding={selectedBinding}
          onSelectBinding={onBindingSelect}
        />
      }
    </>
  )
}

export default PriceCard
