import React from 'react'

const SvgComponent = props => (
  <svg width='23px' height='16px' viewBox='0 0 23 16' version='1.1' xmlns='http://www.w3.org/2000/svg'>
      <title>main_icn_SIM_02</title>
      <desc>Created with Sketch.</desc>
      <defs></defs>
      <g id='10x' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
          <g id='Vörusíða---farsími---stór-banner' transform='translate(-1076.000000, -940.000000)'>
              <g id='spekki---farsími' transform='translate(980.000000, 497.000000)'>
                  <g id='kort' transform='translate(1.000000, 443.000000)'>
                      <g id='main_icn_SIM_02' transform='translate(106.500000, 8.000000) rotate(-270.000000) translate(-106.500000, -8.000000) translate(99.500000, -2.500000)'>
                          <path d='M0,19.1052632 L0,1.89473684 C0,0.852631579 0.859090909,-3.63797881e-12 1.90909091,-3.63797881e-12 L9.54545455,-3.63797881e-12 L14,4.42105263 L14,19.1052632 C14,20.1473684 13.1409091,21 12.0909091,21 L1.90909091,21 C0.859090909,21 0,20.1473684 0,19.1052632 Z' id='Shape' stroke='#000000' strokeWidth='0.96' strokeLinecap='round' strokeLinejoin='round' />
                          <path d='M4,16.875 L4,15.125 C4,14.50625 4.54,14 5.2,14 L8.8,14 C9.46,14 10,14.50625 10,15.125 L10,16.875' id='Shape' stroke='#000000' strokeWidth='0.96' strokeLinecap='round' strokeLinejoin='round' transform='translate(7.000000, 15.437500) rotate(-180.000000) translate(-7.000000, -15.437500) ' />
                          <path d='M7.114,13.654 C6.60799747,13.654 6.15150203,13.5660009 5.7445,13.39 C5.33749796,13.2139991 4.98916811,12.9610017 4.6995,12.631 C4.40983188,12.3009984 4.18800077,11.8995024 4.034,11.4265 C3.87999923,10.9534976 3.803,10.4236696 3.803,9.837 C3.803,8.93499549 3.97533161,8.17966971 4.32,7.571 C4.67933513,6.95499692 5.10466421,6.54433436 5.596,6.339 C6.10200253,6.12633227 6.59699758,6.02 7.081,6.02 C7.74833667,6.02 8.29466454,6.15016537 8.72,6.4105 C9.14533546,6.67083464 9.51566509,7.03933095 9.831,7.516 L8.522,8.539 C8.34599912,8.26766531 8.16266762,8.05500077 7.972,7.901 C7.78133238,7.74699923 7.52466828,7.67 7.202,7.67 C6.76933117,7.67 6.41733469,7.84783156 6.146,8.2035 C5.87466531,8.55916845 5.739,9.11099626 5.739,9.859 C5.739,10.2256685 5.77749961,10.5446653 5.8545,10.816 C5.93150038,11.0873347 6.03783265,11.3146658 6.1735,11.498 C6.30916734,11.6813343 6.46866575,11.8169996 6.652,11.905 C6.83533425,11.9930004 7.03333227,12.037 7.246,12.037 C7.5393348,12.037 7.81433205,12.0113336 8.071,11.96 L8.071,10.926 L7.081,10.926 L7.081,9.474 L9.82,9.474 L9.82,13.192 C9.48999835,13.3240007 9.1050022,13.4339996 8.665,13.522 C8.2249978,13.6100004 7.70800297,13.654 7.114,13.654 Z' id='G' fill='#262626' transform='translate(6.817000, 9.837000) rotate(-90.000000) translate(-6.817000, -9.837000) ' />
                      </g>
                  </g>
              </g>
          </g>
      </g>
  </svg>
)

export default SvgComponent
