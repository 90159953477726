import React, { useContext } from 'react'
import styles from './CategoryInfo.module.scss'
import classNames from 'classnames'

const CategoryInfo = ({ title, text}) => {
  return (
    <div className={classNames(styles.container)}>
      <div className='col-xs-12 col-md-9 col-md-offset-1'>
        <h1 className={styles.title}>{title}</h1>
        <div className={styles.text} dangerouslySetInnerHTML={{ __html: text }} />
      </div>
    </div>
  )
}

export default CategoryInfo
