import React from 'react'
import styles from './Tooltip.module.scss'
import RCTooltip from 'rc-tooltip'
import infoIcon from '../../assets/info.png'
import 'rc-tooltip/assets/bootstrap.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons'

const Tooltip = ({
  tip,
  redIcon = false,
  placement = 'top',
  children,
  small,
  style,
  className,
  product,
  iconStyle = {
    width: small ? 16 : 24
  } }
) => {
  return (
    <span className={styles.rcTooltip}>
      <RCTooltip
        mouseEnterDelay={0}
        mouseLeaveDelay={0.1}
        placement={placement}
        destroyTooltipOnHide
        trigger={'hover'}
        overlay={tip}>
        <FontAwesomeIcon icon={faInfoCircle} />
      </RCTooltip>
    </span>
  )
}

export default Tooltip