import AddToCart from './AddToCart'
import Hero from './Hero'
import Card from './Card'
import FixturesServices from './FixturesServices'
import Cards from './Cards'
import Totals from './Totals'
import Modal from './Modal'
import PriceCards from './PriceCards'
import PriceCard from './PriceCard'
import DataCards from './DataCards'
import Slider from './Slider'
import Tooltip from './Tooltip'
import CategoryInfo from './CategoryInfo'

export {
  CategoryInfo,
  AddToCart,
  Card,
  Cards,
  FixturesServices,
  Hero,
  Totals,
  Modal,
  PriceCards,
  PriceCard,
  DataCards,
  Slider,
  Tooltip
}
