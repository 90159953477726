import React, { useState } from 'react'
import { Hero, Cards, Totals } from '../../components'
import { CartProvider } from '../../contexts/Cart'
import { ProductProvider } from '../../contexts/Products'
import { DiscountsProvider } from '../../contexts/Discounts'
import { CategoriesProvider } from '../../contexts/Categories'
import { DidyouknowProvider } from '../../contexts/Didyouknow'

export default () => {
  return (
    <CategoriesProvider>
      <ProductProvider>
        <DiscountsProvider>
          <DidyouknowProvider>
            <CartProvider>
              <Hero
                title={'Komdu til Vodafone'}
                subtitle={'Hvað þarftu?'}
                image={'https://vodafone.is/lisalib/getfile.aspx?itemid=ddbbcb79-41b5-11ea-80e6-00505681d681'} />
              <Cards />
              <Totals />
            </CartProvider>
          </DidyouknowProvider>
        </DiscountsProvider>
      </ProductProvider>
    </CategoriesProvider>
  )
}
