import React, { useContext } from 'react'
import styles from './AddToCart.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/pro-light-svg-icons'
import classnames from 'classnames'
import { CartContext } from '../../contexts/Cart'
import { ProductContext } from '../../contexts/Products'

const AddToCart = ({ click }) => {
  return (
    <button
      className={classnames('btn btn-vodafone', styles.addToCartBtn)}
      onClick={click}
    >
      Velja og halda áfram
    </button>
  )
}

export default AddToCart
